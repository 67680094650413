<template>
  <div class="shell-box">
    <ClaimStepBar :stepList="stepList"
      :step="step"
      :stepIndex.sync="stepIndex"
      :detail="dataDetail"></ClaimStepBar>
    <div class="form">
      <suspensionDetails v-if="dataDetail.lastStatus == 1"
        :detail="dataDetail" />
      <components ref="step-components"
        :detail="dataDetail"
        :is="componentsName"
        :edit="isEdit"
        :id="$route.params.id"
        :processStatus="processStatus"
        :isReOpen="dataDetail.lastStatus == 3"
        :auditData="auditData"
        @Submit="handleSubmitAfter" />
    </div>
    <div class="operating-box"
      v-if="!$route.query.isDisabled">
      <el-button size="mini"
        @click="cancleEdit"
        v-if="isEdit">取消</el-button>
      <el-button size="mini"
        @click="handleBack"
        v-else>返回</el-button>
      <el-button size="mini"
        type="primary"
        v-if="isEdit && stepIndex !== 4"
        @click="handleReset"
        icon="el-icon-refresh-left"
        plain>重置</el-button>
      <el-button size="mini"
        type="danger"
        v-if="auditDone"
        @click="handleWithdraw"
        plain>撤回</el-button>
      <el-button size="mini"
        type="primary"
        v-if="auditDone"
        @click="isNegotiation = true"
        plain>审核协商</el-button>
      <el-button size="mini"
        type="primary"
        icon="el-icon-edit"
        v-if="isEdit"
        @click="handleSave"
        plain>保存</el-button>
      <el-button disabled
        size="mini"
        class="is-disabled"
        type="primary"
        icon="el-icon-edit"
        v-else-if="auditDone"
        plain>保存</el-button>
      <el-button class="is-disabled"
        disabled
        size="mini"
        type="warning"
        icon="el-icon-edit"
        v-else-if="permissionEdit"
        plain>编辑</el-button>
      <el-button size="mini"
        type="warning"
        :disabled="dataDetail.lastStatus === 10 && stepIndex !== 4"
        icon="el-icon-edit"
        v-else-if="
          dataDetail.lastStatus > 4 && !auditDone
        "
        @click="handleEdit"
        plain>编辑</el-button>
      <el-button size="mini"
        type="warning"
        icon="el-icon-edit"
        :disabled="dataDetail.lastStatus === 10 && stepIndex !== 4"
        v-else-if="
          dataDetail.lastStatus == 3 && (stepIndex == 2 || stepIndex == 4)
        "
        @click="handleEdit"
        plain>编辑</el-button>
      <el-button size="mini"
        type="primary"
        icon="el-icon-back"
        v-if="!isEdit && stepIndex > 0"
        @click="handleChangeStepIndex(-1)">上一步</el-button>
      <el-button size="mini"
        type="primary"
        icon="el-icon-right"
        v-if="!isEdit && stepIndex < 4 && !auditDone"
        @click="handleChangeStepIndex(1)">下一步</el-button>
      <el-button size="mini"
        type="primary"
        icon="el-icon-right"
        v-if="!isEdit && stepIndex < 4 && auditDone"
        disabled>下一步</el-button>
      <el-button type="primary"
        icon="el-icon-download"
        plain
        v-if="dataDetail.lastStatus < 3"
        @click="handleDownloadAttachment">下载所有附件</el-button>
      <el-button size="mini"
        type="warning"
        icon="el-icon-edit"
        v-if="stepIndex == 4 && !isEdit && dataDetail.lastStatus > 8 && dataDetail.adjustmentProcessPassDate"
        :disabled="
          dataDetail.lastStatus < 10 || dataDetail.adjustmentProcessStatus != 3
        "
        @click="handleCloseCase"
        style="background: #fca118">结案</el-button>
      <el-button size="mini"
        type="warning"
        icon="el-icon-edit"
        v-else-if="
          stepIndex == 4 &&
          !isEdit &&
          dataDetail.reopenProcessStatus == 3 &&
          dataDetail.lastStatus == 3 && dataDetail.adjustmentProcessPassDate
        "
        @click="handleCloseCase">重开结案</el-button>

      <el-button size="mini"
        icon="el-icon-remove-outline"
        style="background: #c94242"
        type="danger"
        v-if="
          dataDetail.lastStatus > 2 && !isEdit && dataDetail.lastStatus != 4
        "
        @click="isSuspension = true">中止流程</el-button>
      <el-button size="mini"
        icon="el-icon-remove-outline"
        style="background: #c94242"
        type="danger"
        v-else-if="dataDetail.lastStatus == 2 || dataDetail.lastStatus == 4"
        :disabled="dataDetail.reopenProcessStatus == 2"
        @click="isCaseReopen = true">案件重开</el-button>
    </div>
    <claimStopDB :show.sync="isSuspension"
      @submit="handleSuspension" />
    <caseReopen :showDialog.sync="isCaseReopen"
      :caseData="dataDetail"
      @changeList="getDetail" />
    <closeCase :showDialog.sync="isCloseCase"
      :caseData="dataDetail" ref="closeCase"
      @changeList="getDetail" />
    <negotiationDialog v-model="isNegotiation"
      @submit="handleSubmitNegotiation" />
  </div>
</template>

<script>
import ClaimStepBar from "@/views/claimManagement/components/ClaimStepBar.vue";
import suspensionDetails from "@/views/claimManagement/components/suspensionDetails.vue";
import claimStopDB from "@/views/claimManagement/components/dialogBox/claimStopDB.vue";
import oneForm from "@/views/claimManagement/stepOne/nonForm.vue";
import caseReopen from "@/views/claimManagement/components/dialogBox/caseReopen.vue";
import closeCase from "@/views/claimManagement/components/dialogBox/closeCase.vue";
import oneDetails from "@/views/claimManagement/stepOne/nonDetails.vue";
import LiquidatedDamages from "@/views/claimManagement/components/steps/LiquidatedDamages.vue";
import CheckMedia from "@/views/claimManagement/components/steps/CheckMedia.vue";
import ClaimStep from "@/views/claimManagement/components/steps/ClaimStep.vue";
import adjustment from "@/views/claimManagement/components/steps/adjustment.vue";
import negotiationDialog from "@/views/AuditManagement/components/negotiationDialog.vue";
import {
  insNonClaimsCaseDetail,
  insClaimsCaseSuspend,
  getCaseProgressTime,
  insClaimsAttachmentDownload,
  auditWithdrawal,
  getAuditRecord,
  insClaimsCaseGotoYB,
  addNegotiation,
  claimLockCase,
  claimUnlockCase
} from "@/api/claimData";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { formerlyTime2 } from "@/utils/formatDate";
import { downloadZIP } from "@/utils/index";
import dayjs from "dayjs";
export default {
  name: 'claimDetails',
  components: {
    ClaimStepBar,
    claimStopDB,
    oneForm,
    oneDetails,
    LiquidatedDamages,
    CheckMedia,
    ClaimStep,
    adjustment,
    suspensionDetails,
    caseReopen,
    closeCase,
    negotiationDialog
  },
  data() {
    return {
      dataDetail: {},
      updateRecordDetail: [],
      showUpload: false,
      dataEcho: {},
      stepList: [
        {
          value: 0,
          title: "已接案",
          subtitle: "出险信息",
          completeDate: "完成时间",
          updateTime: "修改时间",
        },
        {
          value: 1,
          title: "验标",
          subtitle: "验标照片和视频",
          completeDate: "完成时间",
          updateTime: "修改时间",
        },
        {
          value: 2,
          title: "定损",
          subtitle: "定损信息与单据",
          completeDate: "完成时间",
          updateTime: "修改时间",
        },
        {
          value: 3,
          title: "索赔",
          subtitle: "索赔材料单据",
          completeDate: "完成时间",
          updateTime: "修改时间",
        },
        {
          value: 4,
          title: "理算结案",
          subtitle: "理算/核赔",
          completeDate: "完成时间",
          updateTime: "修改时间",
        },
      ],
      step: 0,
      stepIndex: 0,
      stepComponents: {
        edit: [
          "oneForm",
          "CheckMedia",
          "LiquidatedDamages",
          "ClaimStep",
          "adjustment",
        ],
        details: [
          "oneDetails",
          "CheckMedia",
          "LiquidatedDamages",
          "ClaimStep",
          "adjustment",
        ],
      },
      isEdit: false,
      isSuspension: false,
      processStatus: {
        0: "发起申请",
        1: "审核驳回",
        2: "待审核",
        3: "审核通过",
        4: "已撤回",
        [-1]: "刚提交,待审核",
      },
      isCaseReopen: false,
      isCloseCase: false,
      isAudit: false,
      isWithdrawal: false,
      auditData: {},
      isNegotiation: false
    };
  },
  created() {
    console.log(1);
    this.getDetail();
    this.$once("setStepIndex", (e) => {
      if (this.dataDetail.subjectInspectionProcessStatus == 2 || this.dataDetail.subjectLossProcessStatus == 2 || this.dataDetail.adjustmentProcessStatus == 2) {
        if (this.dataDetail.subjectInspectionProcessStatus == 2) {
          this.stepIndex = 1;
        } else if (this.dataDetail.subjectLossProcessStatus == 2) {
          this.stepIndex = 2;
        } else if (this.dataDetail.adjustmentProcessStatus == 2) {
          this.stepIndex = 4;
        }
        return false;
      }
      this.stepIndex = e;
    });
  },
  activated() {
    this.getDetail();
    this.$once("setStepIndex", (e) => {
      if (this.dataDetail.subjectInspectionProcessStatus == 2 || this.dataDetail.subjectLossProcessStatus == 2 || this.dataDetail.adjustmentProcessStatus == 2) {
        if (this.dataDetail.subjectInspectionProcessStatus == 2) {
          this.stepIndex = 1;
        } else if (this.dataDetail.subjectLossProcessStatus == 2) {
          this.stepIndex = 2;
        } else if (this.dataDetail.adjustmentProcessStatus == 2) {
          this.stepIndex = 4;
        }
        return false;
      }
      this.stepIndex = e;
    });
  },
  computed: {
    componentsName() {
      let name = "oneDetails";
      if (this.isEdit) {
        name = this.stepComponents.edit[this.stepIndex];
      } else {
        name = this.stepComponents.details[this.stepIndex];
      }
      return name;
    },
    //是否审核完成/中
    auditDone() {
      if (this.stepIndex == 1) {
        return this.dataDetail.subjectInspectionProcessStatus == 2;
      } else if (this.stepIndex == 2) {
        return this.dataDetail.subjectLossProcessStatus == 2;
      } else if (this.stepIndex == 4) {
        return this.dataDetail.adjustmentProcessStatus == 2;
      } else {
        return false;
      }
    },
    //是否允许编辑
    permissionEdit() {
      return this.dataDetail.subjectInspectionProcessStatus == 2 || this.dataDetail.subjectLossProcessStatus == 2 || this.dataDetail.adjustmentProcessStatus == 2;
    },
    withDraw() {
      if (this.stepIndex == 1) {
        this.isWithdrawal = this.dataDetail.subjectInspectionProcessStatus == 2;
      } else if (this.stepIndex == 2) {
        this.isWithdrawal = this.dataDetail.subjectLossProcessStatus == 2;
      } else {
        this.isWithdrawal = false;
      }
    },
  },
  watch: {
    stepIndex: {
      handler(v) {
        this.isEdit = false;
        this.getDetail();
      },
    },
  },
  methods: {
    async getDetail() {
      //获取审核记录
      if (this.stepIndex > 0 && this.stepIndex != 3) {
        let auditResult = await getAuditRecord({
          approvalType: this.stepIndex == 4 ? 3 : this.stepIndex,
          caseId: this.$route.params.id,
        });
        this.auditData = auditResult.data;
      }

      ///
      insNonClaimsCaseDetail({
        id: this.$route.params.id,
      })
        .then((res) => {
          if (res.data.policyPlanList && res.data.policyPlanList.length) {
            res.data.policyPlanList = res.data.policyPlanList.map((val) => {
              val.rateType ? "" : (val.rateType = 1);
              return val;
            });
          }
          this.dataDetail = res.data;
          if (res.data.lastStatus) {
            let status = res.data.lastStatus;
            if (status == 1) {
              let statusList = res.data.status.split(",");
              status = Number(statusList[statusList.length - 2]);
            }
            // 1：已中止、2：已结案、3：案件重开、4：重开结案、5：已接案、6：验标中、7：定损中、8：核赔中、9：理算中、10：支付中
            switch (status) {
              case 1:
                this.step = 0;
                break;
              case 2:
                this.step = 5;
                break;
              case 3:
                this.step = 4;
                break;
              case 4:
                this.step = 5;
                break;
              case 5:
                this.step = 0;
                break;
              case 6:
                this.step = 1;
                break;
              case 7:
                this.step = 2;
                break;
              case 8:
                this.step = 3;
                break;
              case 9:
                this.step = 4;
                break;
              case 10:
                this.step = 4;
                break;
            }
            this.$emit("setStepIndex", this.step > 4 ? 4 : this.step);
          }
          let dataBackShow = [];
          let idInd = 1;
          res.data.attachments
            ? Object.keys(res.data.attachments).forEach(function (key) {
              let type = (key + "").split("-");
              dataBackShow.push({
                type: type[0] == "qt" ? undefined : type[0],
                selectValue: Number(type[1]),
                urls: res.data.attachments[key].map((val) => {
                  idInd++;
                  return {
                    suffix: val.fileType,
                    name: val.fileName,
                    url: val.url,
                    id: idInd,
                  };
                }),
              });
            })
            : "";
          this.dataEcho = dataBackShow;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
      getCaseProgressTime(this.$route.params.id).then((res) => {
        this.stepList = [
          {
            value: 0,
            title: "已接案",
            subtitle: "出险信息",
            completeDate: res.data.caseCompleteTime
              ? "完成时间：" + res.data.caseCompleteTime
              : "",
            updateTime: res.data.caseLastUpdateTime
              ? "修改时间：" + res.data.caseLastUpdateTime
              : "",
          },
          {
            value: 0,
            title: "验标",
            subtitle: "验标照片和视频",
            completeDate: res.data.caseCheckSubjectCompleteTime
              ? "完成时间：" + res.data.caseCheckSubjectCompleteTime
              : "",
            updateTime: res.data.caseCheckSubjectLastUpdateTime
              ? "修改时间：" + res.data.caseCheckSubjectLastUpdateTime
              : "",
          },
          {
            value: 0,
            title: "定损",
            subtitle: "定损信息与单据",
            completeDate: res.data.caseLossCompleteTime
              ? "完成时间：" + res.data.caseLossCompleteTime
              : "",
            updateTime: res.data.caseLossLastUpdateTime
              ? "修改时间：" + res.data.caseLossLastUpdateTime
              : "",
          },
          {
            value: 0,
            title: "索赔",
            subtitle: "索赔材料单据",
            completeDate: res.data.caseClaimCompleteTime
              ? "完成时间：" + res.data.caseClaimCompleteTime
              : "",
            updateTime: res.data.caseClaimLastUpdateTime
              ? "修改时间：" + res.data.caseClaimLastUpdateTime
              : "",
          },
          {
            value: 0,
            title: "理算结案",
            subtitle: "理算/核赔",
            completeDate: res.data.caseCalculateCompleteTime
              ? "完成时间：" + res.data.caseCalculateCompleteTime
              : "",
            updateTime: res.data.caseCalculateLastUpdateTime
              ? "修改时间：" + res.data.caseCalculateLastUpdateTime
              : "",
          },
        ];
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleChangeStepIndex(e) {
      if (this.dataDetail.lastStatus == 5) {
        insClaimsCaseGotoYB({ caseId: this.dataDetail.id }).then((res) => {
          this.$once("setStepIndex", (e) => {
            this.stepIndex = e;
          });
          this.getDetail();
        });
        return false;
      }
      if (!(this.stepIndex + e > this.step || this.stepIndex + e < 0)) {
        this.stepIndex += e;
      } else if (this.stepIndex + e < 0) {
        this.stepIndex = 0;
      } else if (this.stepIndex + e > this.step) {
        this.stepIndex = this.step;
      }
    },
    handleSuspension(e) {
      insClaimsCaseSuspend({
        ...e,
        id: this.dataDetail.id,
      }).then((res) => {
        this.isSuspension = false;
        this.getDetail();
      });
    },
    handleReset() {
      this.$refs["step-components"].handleReset();
      this.$forceUpdate();
    },
    handleSave() {
      this.$refs["step-components"].handleSave();
    },
    // 下载附件
    handleDownloadAttachment() {
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      insClaimsAttachmentDownload({
        caseId: this.dataDetail.id,
      })
        .then((res) => {
          let arr = [];
          res.data.forEach((val) => {
            if (val.attachmentUrl || val.url) {
              let type = (val.attachmentUrl || val.url).split(".");
              arr.push({
                name: val.fileName + "." + type[type.length - 1],
                url: val.attachmentUrl || val.url,
              });
            }
          });
          if (res.data && res.data.length) {
            downloadZIP(arr, this.dataDetail.caseNo)
              .then((res) => {
                loading.close();
              })
              .catch((err) => {
                loading.close();
              });
          } else {
            this.$message.warning("暂无可下载的附件！");
            loading.close();
          }
        })
        .catch((err) => {
          loading.close();
        });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleDataPermissions(key, perms) {
      return dataPermissions(key, perms);
    },
    cancleEdit() {
      claimUnlockCase({ caseId: this.dataDetail.id });
      this.isEdit = false;
    },
    handleEdit() {
      if (this.stepIndex == 2) {
        this.isEdit = true;
        // this.$confirm("理算审核已通过, 定损信息不能再修改", "修改", {
        //   confirmButtonText: "确定",
        //   showCancelButton: false,
        //   type: "warning",
        // });
      } else {
        this.isEdit = true;
      }
      let temp = null;
      if (this.stepIndex == 1) temp = 1;
      if (this.stepIndex == 3) temp = 2;
      if (temp) {
        claimLockCase({ caseId: this.dataDetail.id, status: temp }).then(res => {
          console.log(res);
        });
      }
    },
    handleWithdraw() {
      //let {audit,processId} = this.$refs["step-components"].handleWithdraw()
      auditWithdrawal({ processId: this.auditData.processId }).then((res) => {
        this.isWithdrawal = true;
        this.getDetail();
        this.$forceUpdate();
      });
    },
    async handleCloseCase() {
      let flag = await this.$refs["step-components"].handleVerify();
      if (flag) {
        let Num = 0;
        this.$refs.closeCase.picList = this.$refs["step-components"].picList;
        this.$refs["step-components"].form.payeeList &&
          this.$refs["step-components"].form.payeeList.length
          ? (this.$refs["step-components"].form.payeeList.map((val) => {
            if (val.payee !== '广州美捷保险公估有限公司' && (val.costItem != '差旅费' && val.costItem != '公估费' && val.costItem != '其他费用')) {
            // if (val.costItem != '公估费') {
              Num += val.payAmount ? Number(val.payAmount) : 0;
            }
          }), this.$refs.closeCase.rulesData.payeeList = this.$refs["step-components"].form.payeeList)
          : "";
        Object.assign(this.dataDetail, {
          indemnityAmount: Num.toFixed(2),
          //  closeCaseTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          //  closeDurationTime: formerlyTime(this.dataDetail.reportCaseTime),
          closeCaseTime: this.dataDetail.adjustmentProcessPassDate,
          closeDurationTime: formerlyTime2(this.dataDetail.reportCaseTime, this.dataDetail.adjustmentProcessPassDate),
          accuracy:
            this.dataDetail.estimateLoss && Num
              ? this.dataDetail.estimateLoss > Num
                ? ((Num / this.dataDetail.estimateLoss) * 100).toFixed(2) + "%"
                : ((this.dataDetail.estimateLoss / Num) * 100).toFixed(2) + "%"
              : "0%",
        });
        this.isCloseCase = true;
      } else {
        this.$message.error("请完善信息再结案");
      }
    },
    handleSubmitAfter(type) {
      this.isEdit = false;
      this.getDetail();
      this.$forceUpdate();
    },
    //  添加协商记录
    handleSubmitNegotiation(e) {
      addNegotiation({
        ...e,
        caseId: this.dataDetail.id,
        processType: this.stepIndex == 4 ? 3 : this.stepIndex
      }).then(res => {
        this.isNegotiation = false;
        this.getDetail();
      }).catch(err => {
        console.log(err);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: auto;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
