<template>
  <div>
    <policyForm ref="policyForm"
      :detailData="policyDetail"
      :permissions="policyDetailPermissions"
      :policySubjectList="policySubjectList"
      :policyInsuredList="policyInsuredList" />
  </div>
</template>

<script>
import policyForm from "@/views/claimManagement/nonComponents/applyClaimForm.vue";
import { getPolicyDetail } from "@/api/policy";
import { insNonClaimsCaseUpdate, claimsLossRremoveByCaseIds, claimsDataSheetDelete } from "@/api/claimData";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  props: {
    detail: {
      type: Object,
      default: () => { }
    }
  },
  components: { policyForm },
  data() {
    return {
      policyDetail: {},
      policySubjectList: [],
      policyInsuredList: [],
      policyDetailPermissions: {
        // 车架号
        frameNo: true,
        // 案件跟进人
        caseFollower: true,
        // 现场联系人手机号
        siteContactPhone: true,
        // 出险类型适用条款
        dangerTypeClause: true,
        // 业务员
        policySalesman: true,
        // 报案人姓名
        reportCasePersonName: true,
        // 预估损失金额
        estimateLoss: true,
        // 保司报案号
        realCaseNo: true,
        // 保单号
        policyNo: true,
        // 报案人联系电话
        reportCasePhone: true,
        // 设备型号
        equipmentType: true,
        // 现场联系人姓名
        siteContactName: true,
        // 保险公司
        policyCompany: true,
        // 第二被保险人
        policySecondInsured: true,
        // 出厂日期
        productionDate: true,
        // 投保省份
        policyProvince: true,
        // 第一被保险人
        policyFirstInsured: true,
        // 报案时间
        reportCaseTime: true,
        // 设备品牌
        brand: true,
        // 设备类型
        deviceType: true,
        // 事故原因
        accidentCause: true,
        // 案件责任比例
        dutyRate: true,
        // 设备受损部位
        damagedParts: true,
        // 案件编号
        caseNo: true,
        // 小时数
        workTime: true,
        // 投保方式
        policyApplicantMode: true,
        // 车架号后6位
        vinLast: true,
        // 出险时间
        dangerDate: true,
        // 出险信息备注
        dangerRemark: true,
        // 险种名称
        policyInsuranceType: true,
        // 整备质量
        curbWeight: true,
        // 投保门店
        policyApplicantStore: true,
        // 理算专员
        adjustmentAssistant: true,
        // 区域经理
        regionalManager: true,
        // 出险类型
        dangerTypeId: true,
        // 出险详细地址
        dangerDetailAddress: true,
        policyTime: true,
        // 所属项目
        projectName: true,
      }
    };
  },
  watch: {
    detail: {
      handler(v) {
        if (v.policyId) {
          this.getDetail(v.policyId);
        } else {
          this.policyDetail = {};
          this.policySubjectList = [];
          this.policyInsuredList = [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getDetail(id) {
      getPolicyDetail({
        id
      }).then(res => {
        this.policyDetail = {
          ...this.detail,
         //  "policyApplicantMode": res.data.applicantModeName,
         //  "policyApplicantStore": res.data.applicantStoreName,
         //  "policyCompany": res.data.acceptCompanyList && res.data.acceptCompanyList.length ? res.data.acceptCompanyList[0].companyName : '',
         //  "policyEndDate": res.data.endDate,
         //  "policyFirstInsured": res.data.policyInsuredList && res.data.policyInsuredList.length ? res.data.policyInsuredList[0].playersName : '',
         //  "policyId": res.data.id,
         //  "policyInsuranceType": res.data.policyPlanList && res.data.policyPlanList.length ? res.data.policyPlanList[0].insuranceTypeName : '',
         //  "policyNo": res.data.policyNo,
         //  "policyProvince": res.data.province,
         //  "policySalesman": res.data.salesmanName,
         //  "policySecondInsured": res.data.policyInsuredList && res.data.policyInsuredList.length > 1 ? res.data.policyInsuredList[1].playersName : '',
         //  "policyStartDate": res.data.startDate,
        };
        this.policySubjectList = res.data.policySubjectList;
        res.data.policyInsuredList && res.data.policyInsuredList.length > 1 ? res.data.policyInsuredList.splice(0,1) : '';
        this.policyInsuredList = res.data.policyInsuredList || [];
        this.originalPolicyDetail = JSON.parse(JSON.stringify(this.policyDetail));
      }).catch(err => {
        console.log(err);
      });
    },
    handleReset() {
      this.$refs.policyForm.handleReset();
      this.$forceUpdate();
    },
    async handleSave() {
      let data = await this.$refs.policyForm.handleSubmit();
      if (data.resetObj && this.policyDetail.lastStatus === 10) {
        this.$confirm(`${ data.resetObj === 'policy' ? '理算审核已通过, 保单信息不能再修改' : '理算审核已通过, 标的信息不能再修改' }`, '修改', {
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning'
        }).then(() => {
          this.$emit('Submit');
        }).catch(() => {
        });
        return false;
      } else if (data.resetObj && (this.policyDetail.lastStatus > 6 || this.policyDetail.lastStatus == 3)) {
        this.$confirm(`${ data.resetObj === 'policy' ? '修改保单信息将重置定损和索赔模块, 是否继续' : '修改标的信息将重置定损模块, 是否继续' }`, '修改', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          insNonClaimsCaseUpdate({ ...data }).then(res => {
            if (res) {
              this.$emit('Submit');
              if (data.resetObj === 'policy') {
                claimsLossRremoveByCaseIds({ caseIds: data.id });
                claimsDataSheetDelete({ caseId: data.id });
              }
              claimsLossRremoveByCaseIds({ caseIds: data.id });
              this.$message.success('编辑成功');
            }
          }).catch(err => {
            console.log(err);
          });
        }).catch(() => {
        });
      } else {
        insNonClaimsCaseUpdate({ ...data }).then(res => {
          if (res) {
            this.$emit('Submit');
            this.$message.success('编辑成功');
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
  }
}
</script>

<style>
</style>