<template>
  <div class="step-one">
    <AssociationScroll :showTabs="false">
      <AssociationScrollView title="保单信息"
        name="0">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in policyInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="(item.key instanceof Array)">
                <span v-for="(value, index) in item.key"
                  :key="value">{{ detail[value] }}{{ index>=item.key.length -1 ? '' : ' ~ ' }}</span>
              </span>
              <span v-else>{{ detail[item.key] }}</span>
            </div>
          </el-col>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="标的信息"
        name="1">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in subjectInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="(item.key instanceof Array)">
                <span v-for="(value, index) in item.key"
                  :key="value">{{ detail[value] }}{{ index>=item.key.length -1 ? '' : ' ~ ' }}</span>
              </span>
              <span v-else>{{ detail[item.key] }}</span>
            </div>
          </el-col>
          <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24">
            <div class="detail-text"
              style="font-size: 14px;font-weight: bold;color: #333333;">出险历史</div>
            <el-table :data="policySubjectHistoryList"
              border
              style="margin: 0;">
              <el-table-column label="序号"
                type="index"
                width="50"></el-table-column>
              <el-table-column label="保单号"
                prop="policyNo"
                width="180">
                <template slot-scope="{row}">
                  <div class="el-button polecy-button el-button--text el-button--small"
                    style="text-decoration:underline;padding: 0;"
                    @click="handleGoPath('/routerPolicyManagement/ConstructionMachinery/policyDetails/' + row.policyId)">{{ row.policyNo }}</div>
                </template>
              </el-table-column>
              <el-table-column label="案件编号"
                prop="caseNo"
                width="180">
                <template slot-scope="{row}">
                  <div class="el-button polecy-button el-button--text el-button--small"
                    style="text-decoration:underline;padding: 0;"
                    @click="handleGoPath('/routerClaimManagement/claimDetails/' + row.id)">{{ row.caseNo }}</div>
                </template>
              </el-table-column>
              <el-table-column label="报案号"
                prop="realCaseNo"
                width="180"></el-table-column>
              <el-table-column label="保险公司"
                prop="policyCompany"></el-table-column>
              <el-table-column label="出险类型"
                prop="dangerType"></el-table-column>
              <el-table-column label="出险时间"
                prop="dangerDate"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="机手信息"
        name="3">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in jiShouInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="item.isSwicth">
                <textSwitch v-model="detail[item.key]"
                  disabled
                  active-text="无"
                  inactive-text="有" />
              </span>
              <span v-else>{{ detail[item.key] }} {{ item.suffix }}</span>
            </div>
          </el-col>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="出险信息"
        name="2">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in dangerInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="(item.key instanceof Array)">
                <span v-for="(value, index) in item.key"
                  :key="value">{{ detail[value] }}{{ index>=item.key.length -1 ? '' : ' ~ ' }}</span>
              </span>
              <span v-if="item.isSwicth">
                <textSwitch v-model="detail[item.key]"
                  disabled
                  active-text="否"
                  inactive-text="是" />
              </span>
              <span v-else>{{ detail[item.key] }} {{ item.suffix }}</span>
            </div>
          </el-col>
          <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            v-if="bulkPermissions.accidentCause">
            <div class="detail-text">简述事故发生原因：{{ detail.accidentCause }}</div>
          </el-col>
          <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            v-if="bulkPermissions.dangerRemark">
            <div class="detail-text">备注</div>
            <el-input type="textarea"
              placeholder="请输入内容"
              v-model="detail.dangerRemark"
              maxlength="100"
              rows="6"
              disabled
              show-word-limit></el-input>
          </el-col>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="报案信息"
        name="8">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in reportCaseInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="(item.key instanceof Array)">
                <span v-for="(value, index) in item.key"
                  :key="value">{{ detail[value] }}{{ index>=item.key.length -1 ? '' : ' ~ ' }}</span>
              </span>
              <span v-else>{{ detail[item.key] }}</span>
            </div>
          </el-col>
        </el-row>
      </AssociationScrollView>
    </AssociationScroll>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import textSwitch from "@/components/textSwitch/index.vue";
import { insNonClaimsCasePage } from "@/api/claimData";
import { hasPerms, dataPermissions } from "@/utils/auth";
import DialogVideo from "@/components/DialogVideo";
import { downloadZIP } from "@/utils/index";
export default {
  components: { AssociationScroll, AssociationScrollView, textSwitch, DialogVideo },
  props: {
    detail: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    policyInformation() {
      return this.showContentData.policyData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    },
    subjectInformation() {
      return this.showContentData.subjectData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    },
    dangerInformation() {
      return this.showContentData.dangerData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    },
    jiShouInformation() {
      return this.showContentData.jiShouData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    },
    reportCaseInformation() {
      return this.showContentData.reportCaseData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    }
  },
  data() {
    return {
      showContentData: {
        policyData: [
          {
            label: '保单号：',
            key: 'policyNo',
            isAuthority: true,
          },
          {
            label: '第一被保险人：',
            key: 'policyFirstInsured',
            isAuthority: true
          },
          {
            label: '第二被保险人：',
            key: 'policySecondInsured',
            isAuthority: true,
            isExist: !this.detail.policySecondInsured
          },
          {
            label: '保障期限：',
            key: ['policyStartDate', 'policyEndDate'],
            isAuthority: true
            // dataPermissions('M21-View', 'dueTime')
          },
          {
            label: '险种名称：',
            key: 'policyInsuranceType',
            isAuthority: true,
          },
          {
            label: '保险公司：',
            key: 'policyCompany',
            isAuthority: true,
          },
          {
            label: '跟进人：',
            key: 'caseFollower',
            isAuthority: true,
          },
          // {
          //   label: '理算员：',
          //   key: 'adjustmentAssistant',
          //   isAuthority: dataPermissions('M21-View', 'adjustmentAssistant')
          // },
        ],
        subjectData: [
          {
            label: '出险设备：',
            key: 'frameNo',
            isAuthority: true,
          },
          {
            label: '设备类型：',
            key: 'deviceType',
            isAuthority: true,
          },
          {
            label: '品牌：',
            key: 'brand',
            isAuthority: true,
          },
          {
            label: '型号：',
            key: 'equipmentType',
            isAuthority: true,
          },
          {
            label: '车架号：',
            key: 'frameNo',
            isAuthority: true,
          },
          {
            label: '整备质量：',
            key: 'curbWeight',
            isAuthority: true,
          },
          {
            label: '出厂日期：',
            key: 'productionDate',
            isAuthority: true,
          },
          {
            label: '小时数：',
            key: 'workTime',
            isAuthority: true,
          },
          {
            label: '报案当天连续作业工时：',
            key: 'workTimeDay',
            isAuthority: true,
          },
          {
            label: '近30天日均作业工时：',
            key: 'workTimeAverageMonth',
            isAuthority: true,
          },
        ],
        jiShouData: [
          {
            label: '机手姓名：',
            key: 'jiShouName',
            isAuthority: true,
          },
          {
            label: '机手电话：',
            key: 'jiShouPhone',
            isAuthority: true,
          },
          {
            label: '是否有操作证：',
            key: 'isControls',
            isSwicth: true,
            isAuthority: true,
          },
          {
            label: '机手年龄：',
            key: 'jiShouAge',
            isAuthority: true,
          },
          {
            label: '机手工龄：',
            key: 'jiShouExperience',
            isAuthority: true,
          },
          {
            label: '机手证件号：',
            key: 'jiShouIdNumber',
            isAuthority: true,
          },
             
        ],
        dangerData: [
          {
            label: '出险类型：',
            key: 'dangerType',
            isAuthority: true,
          },
          {
            label: '出险类型细分：',
            key: 'dangerTypeDetails',
            isAuthority: true,
          },
          {
            label: '事故类型：',
            key: 'accidentType',
            isAuthority: true,
          },
          {
            label: '损失种类：',
            key: 'lossType',
            isAuthority: true,
          },
          {
            label: '出险地点：',
            key: ['dangerProvince', 'dangerCity', 'dangerCounty'],
            isAuthority: true
          },
          {
            label: '详细地址：',
            key: 'dangerDetailAddress',
            isAuthority: true,
            isAuthority: true,
          },
          {
            label: '所属省份：',
            key: 'dangerProvince',
            isAuthority: true,
          },
          {
            label: '出险时间：',
            key: 'dangerDate',
            isAuthority: true,
          },
          {
            label: '案件责任比例：',
            suffix: '%',
            key: 'dutyRate',
            isAuthority: true,
          },
          {
            label: '出险类型适用保险条款：',
            key: 'dangerTypeClause',
            isAuthority: true,
          },
          {
            label: '设备受损部位：',
            key: 'damagedParts',
            isAuthority: true,
          },
          {
            label: '预估损失金额：',
            key: 'estimateLoss',
            isAuthority: true,
          },
          {
            label: '现场联系人：',
            key: 'siteContactName',
            isAuthority: true,
          },
          {
            label: '现场联系电话：',
            key: 'siteContactPhone',
            isAuthority: true,
          },
          {
            label: '报案时刻现场天气：',
            key: 'weather',
            isAuthority: true,
          },
          {
            label: '简述事故发生原因：',
            key: 'accidentCause',
            isAuthority: true,
          },
          {
            label: '是否需要施救：',
            key: 'rescue',
            isAuthority: true,
            isSwicth: true,
          },
          {
            label: '三者案件有无三方证明：',
            key: 'prove',
            isAuthority: true,
            isSwicth: true,
          },
          {
            label: '是否一次事故造成损失：',
            key: 'secondaryAccident',
            isAuthority: true,
            isSwicth: true,
          },
          {
            label: '非一次事故造成损失说明：',
            key: 'notOneDamagedDesc',
            isAuthority: true,
            isExist: !this.detail.secondaryAccident
          },
        ],
        reportCaseData: [
          {
            label: '报案人姓名：',
            key: 'reportCasePersonName',
            isAuthority: true,
          },
          {
            label: '报案人电话：',
            key: 'reportCasePhone',
            isAuthority: true,
          },
          {
            label: '报案时间：',
            key: 'reportCaseTime',
            isAuthority: true,
          },
          // {
          //   label: '保司报案号：',
          //   key: 'realCaseNo',
          //   isAuthority: dataPermissions('M21-View', 'realCaseNo')
          // },
          // {
          //   label: '案件编号：',
          //   key: 'caseNo',
          //   isAuthority: dataPermissions('M21-View', 'caseNo')
          // },
          // {
          //   label: '出险设备车架号后8位：',
          //   key: 'vinLast',
          //   isAuthority: dataPermissions('M21-View', 'vinLast'),
          //   isExist: !this.detail.subjectUnknown
          // },
        ]
      },
      bulkPermissions: {
        dangerRemark: true,
        accidentCause: true,
      },
      policySubjectHistoryList: [],
      decorationIndex: 1,
      mapall: null,
      marker: null,
    };
  },
  watch: {
    detail: {
      handler(v) {
        if (v.frameNo) {
          this.handleGetInit();
        } else {
          this.policySubjectHistoryList = [];
        }
      },
      deep: true,
      immediate: true
    },
    decorationIndex: {
      handler(v) {
        if (v == 2) {
          this.initMap();
        }
      },
    },
  },
  methods: {
    handleGetInit() {
      insNonClaimsCasePage({
        frameNo: this.detail.frameNo,
        excludeCaseId: this.detail.id ? [this.detail.id] : [],
        current: 1,
        size: 999
      }).then(res => {
        if (res.data && res.data.records && res.data.records.length) {
          this.policySubjectHistoryList = res.data.records;
        } else {
          this.policySubjectHistoryList = [];
        }
      });
    },
    handleGoPath(path) {
      this.$router.push({ path });
    },
    downLoadClick() {
      let urls = []
      if (!this.detail.hardware.hardwareAlarmAttachVO || !this.detail.hardware.hardwareAlarmAttachVO.length) {
        this.$message.error('暂无附件可下载');
        return false;
      }
      this.detail.hardware.hardwareAlarmAttachVO.map((val,ind)=>{
        val.map((val2,ind2)=> {
          urls.push({
            url: val2.filePath,
            name:  val2.filePath.split('/')[val2.filePath.split('/').length -1 ]
          });
          //  + '.' + val2.filePath.split('.')[val2.filePath.split('.').length -1 ]
          if (ind == this.detail.hardware.hardwareAlarmAttachVO.length -1 && ind2 == this.detail.hardware.hardwareAlarmAttachVO[ind].length - 1) {
            this.$nextTick(()=>{
              downloadZIP(urls, `${ this.detail.hardware.hardwareVehicleVO.plate }-${this.details.caseFromSourceType == 1 ? "小程序报案" : "终端报案"}-${this.detail.hardware.hardwareVehicleVO.accidentTime}.zip`);
            })
          }
        })
      })
    },
    initMap() {
      const that = this;
      let Lng = "";
      let Lat = "";
      if (
        this.detail.hardware && this.detail.hardware.hardwareAlarmVO &&
        this.detail.hardware.hardwareAlarmVO.length
      ) {
        Lng = this.detail.hardware.hardwareAlarmVO[0].lon;
        Lat = this.detail.hardware.hardwareAlarmVO[0].lat;
      }else {
        this.$message.error('暂无报警定位信息')
        return false;
      }
      var content = [
          `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>设备名称</div><div style='font-size:14px;color: #333;'>${ this.detail.hardware.hardwareVehicleVO.plate }</div></div>`,
          `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>报警类型</div><div style='font-size:14px;color: #333;'>${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].alarmName : '-' }</div></div>`,
          `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>报警时间</div><div style='font-size:14px;color: #333;'>${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].createTime : '-' }</div></div>`,
          `<div style='display:flex;width:100%;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>具体地址</div><div style='font-size:14px;color: #333;'>${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].alarmProvince : '-' }${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].alarmCity : '-' }${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].alarmArea : '-' }</div></div>`
      ];

      // 创建 infoWindow 实例	
      var infoWindow = new AMap.InfoWindow({
        content: content.join(""),  //传入 dom 对象，或者 html 字符串
        offset: new AMap.Pixel(0, -32)
      });
        
      if (that.mapall) {
        that.mapall.setCenter([Lng, Lat]);
        that.mapall.setZoom(15);
        that.mapall.remove(this.marker);
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
        // 打开信息窗体
        infoWindow.open(that.mapall, [Lng, Lat]);
      } else {
        var map = new AMap.Map(that.$refs.container, {
          resizeEnable: true,
          center: [Lng, Lat],
          zoom: 15,
        });
        that.mapall = map;
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
        // 打开信息窗体
        infoWindow.open(that.mapall, [Lng, Lat]);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-text {
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}
</style>
<style lang="scss">
.step-one {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
  .statistical-box {
    // width: 820px;
    padding: 24px 6px;
    &-title {
      position: relative;
      padding-left: 13px;
      &-text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      &-time {
        color: #cccccc;
        font-size: 12px;
      }
    }
    &-title::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: #4278c9;
      border-radius: 3px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 24px;
      > div {
        width: 46%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        > div {
          width: 112px;
          color: #999;
          margin-bottom: 23px;
          margin-right: 16px;
          text-align: right;
        }
        span {
          flex: 1;
          color: #333333;
        }
      }
    }
    &-decoration {
      &-top {
        display: flex;
        justify-content: space-between;
        &-tab {
          display: flex;
          div {
            width: 88px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            color: #333333 !important;
            cursor: pointer;
            border-radius: 8px 8px 0px 0px;
            border: 1px solid #cccccc !important;
            border-bottom: none;
          }
          &-current {
            color: #333 !important;
            border: 1px solid #dee3e6 !important;
            background: #dee3e6 !important;;
          }
        }
        &-download {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #0080ff;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            margin-left: 2px;
            text-decoration: underline;
          }
        }
      }
      &-down {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        padding: 16px 0;
        height: 290px;
        overflow: auto;
        border-radius: 0px 8px 8px 8px;
        border: 1px solid #dee3e6 !important;
        &-content {
          width: 128px;
          height: 80px;
          margin-bottom: 8px;
          margin-left: 15px;
          border-radius: 4px;
        }
        &-box {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &-time {
            width: 153px;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #ffffff;
            padding-left: 8px;
            margin: 8px;
            margin-left: 16px;
            background: #4278c9;
          }
          &-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            padding: 0 16px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .el-table {
      margin-bottom: 22px;
      .has-gutter {
        tr {
          .el-table__cell {
            background-color: #f2f7fd;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
          .is-leaf {
            border-bottom: 1px solid #dee3e6;
          }
        }
      }
      tr {
        .el-table__cell {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
        .is-leaf {
          border-bottom: 1px solid #dee3e6;
        }
      }
    }
    .amap-info-content {
      padding: 16px 24px;
      background-color: rgba(255,255,255,.9);
      box-shadow: 0px 3px 6px 1px rgba(51,51,51,0.1);
      border-radius: 10px;
    }
    .amap-info-sharp {
      display: none;
    }
  }
}
</style>